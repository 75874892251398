import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Container,
    makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import usersApi from '../../../common/api/users';
import UsersContext from '../../../common/store/contexts/UsersContext';
import FullPageLoader from '../../../components/FullPageLoader/FullPageLoader';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const UserListView = () => {
    const classes = useStyles();
    const context = useContext(UsersContext);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        context.loadUsers([]);
        usersApi.getUsers().then((response) => {
            context.loadUsers(response.data.rows);
            setTotalItems(response.data.totalItems);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });

        return () => {
            // cleanup here
        };
    }, []);

    return (
        <UsersContext.Consumer>
            {(value) => (
                <Page
                    className={classes.root}
                    title="Users"
                >
                    {loading
                        ? <FullPageLoader />
                        : (
                            <Container maxWidth={false}>
                                <Toolbar />
                                <Box mt={3}>
                                    <Results key="users" users={value.users} totalitems={totalItems} />
                                </Box>
                            </Container>
                        )}

                </Page>
            )}
        </UsersContext.Consumer>
    );
};

export default UserListView;
