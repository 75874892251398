import React, { useEffect, useState } from 'react';
import { useNavigate, NavLink as RouterLink, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    Box,
    Button,
    Container,
    TextField,
    makeStyles
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Page from 'src/components/Page';
import authApi from '../../common/api/auth';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const ResetPasswordView = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { resetCode } = useParams();
    const [resetCodeResponse, setResetCodeResponse] = useState({});

    useEffect(() => {
        if (resetCode) {
            authApi.verifyCode(resetCode).then((response) => {
                setResetCodeResponse(response.data.reset_code);
            }).catch(() => {
                navigate('/auth/forgot-password', { replace: true });
            });
        }

        return () => {
            // cleanup here
        };
    }, [resetCode]);

    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: ''
        },
        validationSchema: Yup.object().shape({
            password: Yup.string().min(8).required('Password is required'),
            confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                values = { ...values, ...resetCodeResponse };
                await authApi.resetPassword(values);
                enqueueSnackbar('Password reset successfuly!', { variant: 'success' });
                navigate('/auth/login', { replace: true });
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
            }
        }
    });

    return (
        <Page className={classes.root} title="Forgot Password">
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Container maxWidth="sm">
                    <form noValidate onSubmit={formik.handleSubmit}>
                        <Box mb={1} display="flex">
                            <img className="logo" src="/static/images/logo.png" alt="" />
                        </Box>
                        <TextField
                            error={Boolean(formik.touched.password && formik.errors.password)}
                            fullWidth
                            helperText={formik.touched.password && formik.errors.password}
                            label="Password"
                            margin="normal"
                            name="password"
                            type="password"
                            variant="outlined"
                            {...formik.getFieldProps('password')}
                        />
                        <TextField
                            error={Boolean(formik.touched.confirm_password && formik.errors.confirm_password)}
                            fullWidth
                            helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                            label="Confirm Password"
                            margin="normal"
                            name="confirm_password"
                            type="password"
                            variant="outlined"
                            {...formik.getFieldProps('confirm_password')}
                        />
                        <Box my={2}>
                            <Button color="primary" disabled={formik.isSubmitting} fullWidth size="large" type="submit" variant="contained">
                                Reset Password
                            </Button>
                        </Box>
                        <Box my={2} display="flex" justifyContent="center">
                            <Button
                                activeClassName={classes.active}
                                className={classes.button}
                                component={RouterLink}
                                to="/auth/login"
                            >
                                <span className={classes.title}>
                                    Back to login
                                </span>
                            </Button>
                        </Box>
                    </form>
                </Container>
            </Box>
        </Page>
    );
};

export default ResetPasswordView;
