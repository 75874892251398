export const ADD_USER = 'ADD_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const LOAD_USERS = 'LOAD_USERS';

const loadUsers = (users, state) => {
    return { ...state, users };
};

const addUser = (user, state) => {
    const updateUserList = [...state.users];
    const updatedItemIndex = updateUserList.findIndex(
        item => item.id === user.id
    );

    if (updatedItemIndex < 0) {
        updateUserList.push(user);
    } else {
        console.log(`user with id: ${user.id} already exists`);
    }
    return { ...state, users: updateUserList };
};

const removeUser = (userId, state) => {
    console.log(`Removing user with id: ${userId}`);
    const updateUserList = [...state.users];
    const updatedItemIndex = updateUserList.findIndex(item => item.id === userId);

    if (updatedItemIndex >= 0) {
        updateUserList.splice(updatedItemIndex, 1);
    } else {
        console.log(`user with id: ${userId} does not exists`);
    }
    return { ...state, users: updateUserList };
};

const updateUser = (userId, user, state) => {
    console.log(`upadting user with id: ${userId}`);
    const updateUserList = [...state.users];
    const updatedItemIndex = updateUserList.findIndex(item => item.id === userId);

    if (updatedItemIndex >= 0) {
        updateUserList[updatedItemIndex] = user;
    } else {
        console.log(`user with id: ${userId} does not exists`);
    }
    return { ...state, users: updateUserList };
};

export const userReducer = (state, action) => {
    switch (action.type) {
    case LOAD_USERS:
        return loadUsers(action.users, state);
    case ADD_USER:
        return addUser(action.user, state);
    case REMOVE_USER:
        return removeUser(action.userId, state);
    case UPDATE_USER:
        return updateUser(action.userId, action.user, state);
    default:
        return state;
    }
};
