import React, { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { UserContext } from '../../common/store/contexts/userContext';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto'
    }
}));

const MainLayout = () => {
    const classes = useStyles();
    const { authenticated } = useContext(UserContext);

    if (!authenticated) {
        return (
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <div className={classes.contentContainer}>
                        <div className={classes.content}>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Navigate to="/app" />
    );
};

export default MainLayout;
