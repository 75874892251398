import API from '../utils/API';

const baseUrl = '/auth';

const authApi = {

    login: async (model) => {
        return API.post(`${baseUrl}/login`, model);
    },

    register: (model) => {
        return API.post(`${baseUrl}/register`, model);
    },

    forgotPassword: (model) => {
        return API.post(`${baseUrl}/forgot-pass`, model);
    },

    verifyCode: (code) => {
        return API.post(`${baseUrl}/verify/${code}`, {});
    },

    resetPassword: (model) => {
        return API.post(`${baseUrl}/reset-pass`, model);
    },
};

export default authApi;
