import React, {
    useState,
    useReducer,
    useContext
} from 'react';

import ProjectsContext from './ProjectsContext';
import {
    projectReducer,
    ADD_PROJECT,
    REMOVE_PROJECT,
    UPDATE_PROJECT,
    LOAD_PROJECTS,
} from '../../reducers/reducer';
import UsersContext from './UsersContext';
import {
    userReducer,
    ADD_USER,
    REMOVE_USER,
    UPDATE_USER,
    LOAD_USERS,
} from '../../reducers/user-reducer';

const GlobalState = (props) => {
    const [projects, setProjects] = useState([]);
    const [users, setUsers] = useState([]);
    const [projectsList, dispatch] = useReducer(projectReducer, { projects });
    const [usersList, dispatchUser] = useReducer(userReducer, { users });

    const loadProjects = (projects) => {
        dispatch({ type: LOAD_PROJECTS, projects });
    };

    const addProject = (project) => {
        dispatch({ type: ADD_PROJECT, project });
    };

    const removeProject = (projectId) => {
        dispatch({ type: REMOVE_PROJECT, projectId });
    };

    const updateProject = (projectId, project) => {
        dispatch({ type: UPDATE_PROJECT, projectId, project });
    };

    const loadUsers = (users) => {
        dispatchUser({ type: LOAD_USERS, users });
    };

    const addUser = (user) => {
        dispatchUser({ type: ADD_USER, user });
    };

    const removeUser = (userId) => {
        dispatchUser({ type: REMOVE_USER, userId });
    };

    const updateUser = (userId, user) => {
        dispatchUser({ type: UPDATE_USER, userId, user });
    };

    return (
        <UsersContext.Provider
            value={{
                users: usersList.users,
                addUser,
                removeUser,
                updateUser,
                loadUsers
            }}
        >
            <ProjectsContext.Provider
                value={{
                    projects: projectsList.projects,
                    addProject,
                    removeProject,
                    updateProject,
                    loadProjects,
                }}
            >
                {props.children}
            </ProjectsContext.Provider>
        </UsersContext.Provider>
    );
};

export default GlobalState;
export const useProjectValue = () => useContext(ProjectsContext);
export const useUserValue = () => useContext(UsersContext);
