import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
    makeStyles,
} from '@material-ui/core';
import {
    AddOutlined,
    HomeRounded,
    PeopleAltRounded,
} from '@material-ui/icons';
import { Search as SearchIcon } from 'react-feather';
import UserForm from './UserForm';
import rolesApi from '../../../common/api/roles';
import projectApi from '../../../common/api/projects';
import usersApi from '../../../common/api/users';
import ProjectsContext from '../../../common/store/contexts/ProjectsContext';
import UsersContext from '../../../common/store/contexts/UsersContext';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    addButton: {
        fontSize: 25
    },
    homeButton: {
        fontSize: 25
    },
    usersButton: {
        fontSize: 25
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 400,
        minHeight: 200,
        padding: 20
    }
}));

const Toolbar = ({ className, ...rest }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const projectContext = useContext(ProjectsContext);
    const userContext = useContext(UsersContext);

    useEffect(() => {
        setLoading(true);
        rolesApi.getRoles().then((res) => {
            setRoles(res.data);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            console.log(error);
        });

        setLoading(true);
        projectApi.getProjects().then((response) => {
            projectContext.loadProjects(response.data.rows);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });

        return () => {
            // cleanup here
        };
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const dialogStatHandler = (status) => {
        setOpen(status);
    };

    const handleSearch = async (event) => {
        try {
            setLoading(true);
            const response = await usersApi.getUsers(event.target.value);
            userContext.loadUsers(response.data.rows);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <div
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Box display="flex" justifyContent="flex-end" data-cy="toolbar">
                <Button onClick={handleClickOpen} data-cy="add-user-button">
                    <SvgIcon color="inherit" className={classes.addButton}>
                        <AddOutlined />
                    </SvgIcon>
                </Button>
                <Button>
                    <SvgIcon color="inherit" className={classes.homeButton}>
                        <HomeRounded />
                    </SvgIcon>
                </Button>
                <Button>
                    <SvgIcon color="inherit" className={classes.usersButton}>
                        <PeopleAltRounded />
                    </SvgIcon>
                </Button>
            </Box>
            <Box mt={3}>
                <Card>
                    <CardContent>
                        <Box maxWidth={500}>
                            <TextField
                                fullWidth
                                onKeyUp={handleSearch}
                                data-cy="user-search"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SvgIcon fontSize="small" color="action">
                                                <SearchIcon />
                                            </SvgIcon>
                                        </InputAdornment>
                                    )
                                }}
                                placeholder="Search Users"
                                variant="outlined"
                            />
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <UserForm
                open={open}
                roles={roles}
                projects={projectContext.projects}
                onChange={(value) => { dialogStatHandler(value); }}
            />
        </div>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string
};

export default Toolbar;
