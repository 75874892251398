export const ADD_PROJECT = 'ADD_PROJECT';
export const REMOVE_PROJECT = 'REMOVE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const LOAD_PROJECTS = 'LOAD_PROJECTS';

const loadProjects = (projects, state) => {
    return { ...state, projects };
};

const addProject = (project, state) => {
    const updateProjectList = [...state.projects];
    const updatedItemIndex = updateProjectList.findIndex(
        item => item.id === project.id
    );

    if (updatedItemIndex < 0) {
        updateProjectList.push(project);
    } else {
        console.log(`project with id: ${project.id} already exists`);
    }
    return { ...state, projects: updateProjectList };
};

const removeProject = (projectId, state) => {
    console.log(`Removing project with id: ${projectId}`);
    const updateProjectList = [...state.projects];
    const updatedItemIndex = updateProjectList.findIndex(item => item.id === projectId);

    if (updatedItemIndex >= 0) {
        updateProjectList.splice(updatedItemIndex, 1);
    } else {
        console.log(`project with id: ${projectId} does not exists`);
    }
    return { ...state, projects: updateProjectList };
};

const updateProject = (projectId, project, state) => {
    console.log(`upadting project with id: ${projectId}`);
    const updateProjectList = [...state.projects];
    const updatedItemIndex = updateProjectList.findIndex(item => item.id === projectId);

    if (updatedItemIndex >= 0) {
        updateProjectList[updatedItemIndex] = project;
    } else {
        console.log(`project with id: ${projectId} does not exists`);
    }
    return { ...state, projects: updateProjectList };
};

export const projectReducer = (state, action) => {
    switch (action.type) {
    case LOAD_PROJECTS:
        return loadProjects(action.projects, state);
    case ADD_PROJECT:
        return addProject(action.project, state);
    case REMOVE_PROJECT:
        return removeProject(action.projectId, state);
    case UPDATE_PROJECT:
        return updateProject(action.projectId, action.project, state);
    default:
        return state;
    }
};
