import React from 'react';
import { useNavigate, NavLink as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    Box,
    Button,
    Container,
    TextField,
    makeStyles
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Page from 'src/components/Page';
import authApi from '../../common/api/auth';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const ForgotPasswordView = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                await authApi.forgotPassword(values);
                enqueueSnackbar('Request sent, Please check your inbox for instructions!', { variant: 'success' });
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
            }
        }
    });

    return (
        <Page className={classes.root} title="Forgot Password">
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Container maxWidth="sm">
                    <form noValidate onSubmit={formik.handleSubmit}>
                        <Box mb={1} display="flex">
                            <img className="logo" src="/static/images/logo.png" alt="" />
                        </Box>
                        <TextField
                            error={Boolean(formik.touched.email && formik.errors.email)}
                            fullWidth
                            autoFocus
                            helperText={formik.touched.email && formik.errors.email}
                            label="Email Address"
                            margin="normal"
                            name="email"
                            type="email"
                            variant="outlined"
                            {...formik.getFieldProps('email')}
                        />
                        <Box my={2}>
                            <Button color="primary" disabled={formik.isSubmitting} fullWidth size="large" type="submit" variant="contained">
                                Submit
                            </Button>
                        </Box>
                        <Box my={2} display="flex" justifyContent="center">
                            <Button
                                activeClassName={classes.active}
                                className={classes.button}
                                component={RouterLink}
                                to="/auth/login"
                            >
                                <span className={classes.title}>
                                    Back to login
                                </span>
                            </Button>
                        </Box>
                    </form>
                </Container>
            </Box>
        </Page>
    );
};

export default ForgotPasswordView;
