import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import UserListView from 'src/views/user/UserListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProjectListView from 'src/views/project/ProjectListView';
import SettingsView from 'src/views/settings/SettingsView';
import NoAuthLayout from './layouts/NoAuthLayout';
import ForgotPasswordView from './views/auth/ForgotPasswordView';
import ResetPasswordView from './views/auth/ResetPasswordView';

const routes = [
    {
        path: 'app',
        element: <DashboardLayout />,
        children: [
            { path: 'account', element: <AccountView /> },
            { path: 'users', element: <UserListView /> },
            { path: 'dashboard', element: <DashboardView /> },
            { path: 'projects', element: <ProjectListView /> },
            { path: 'settings', element: <SettingsView /> },
            { path: '*', element: <Navigate to="/404" /> }
        ]
    },
    {
        path: 'auth',
        element: <MainLayout />,
        children: [
            { path: 'login', element: <LoginView /> },
            { path: 'forgot-password', element: <ForgotPasswordView /> },
            { path: 'reset-password/:resetCode', element: <ResetPasswordView /> },
            { path: '/', element: <Navigate to="/auth/login" /> },
            { path: '*', element: <Navigate to="/404" /> },
        ]
    },
    {
        path: '/',
        element: <NoAuthLayout />,
        children: [
            { path: '/', element: <Navigate to="/auth/login" /> },
            { path: '404', element: <NotFoundView /> },
            { path: '*', element: <Navigate to="/404" /> }
        ]
    }
];

export default routes;
