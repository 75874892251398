import API from '../utils/API';

const baseUrl = '/users';

const userApi = {
    getUsers: async (keyword, page, size) => {
        const params = {
            keyword,
            page,
            size
        };
        const query = Object.keys(params)
            .map((k) => ((params[k]) ? `${k}=${params[k]}` : ''))
            .join('&');
        return API.get(`${baseUrl}?${query}`);
    },

    saveUser: async (model) => {
        return API.post(`${baseUrl}`, model);
    },

    getUserDetail: (id) => {
        return API.get(`${baseUrl}/${id}`);
    },

    updateUserRole: (id, model) => {
        return API.put(`${baseUrl}/${id}`, model);
    },

    deleteUser: (id) => {
        return API.delete(`${baseUrl}/${id}`);
    },

    updateUserDetail: (id, model) => {
        return API.put(`${baseUrl}/${id}/update`, model);
    }
};

export default userApi;
