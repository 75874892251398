import React, { useContext } from 'react';
import { useNavigate, NavLink as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    Box,
    Button,
    Container,
    TextField,
    makeStyles
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Page from 'src/components/Page';
import { UserContext } from 'src/common/store/contexts/userContext';
import authApi from '../../common/api/auth';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const LoginView = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { setAuthenticated } = useContext(UserContext);
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
            password: Yup.string().max(255).required('Password is required')
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const res = await authApi.login(values);
                localStorage.setItem('auth', JSON.stringify(res.data));
                setAuthenticated(JSON.stringify(res.data));
                enqueueSnackbar('Logged in successfully', { variant: 'success' });
                setSubmitting(false);
                navigate('/app/projects', { replace: true });
            } catch (error) {
                setSubmitting(false);
            }
        }
    });

    return (
        <Page className={classes.root} title="Login">
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Container maxWidth="sm">
                    <form noValidate onSubmit={formik.handleSubmit}>
                        <Box mb={1} display="flex">
                            <img className="logo" src="/static/images/logo.png" alt="" />
                        </Box>
                        <TextField
                            error={Boolean(formik.touched.email && formik.errors.email)}
                            fullWidth
                            autoFocus
                            helperText={formik.touched.email && formik.errors.email}
                            label="Email Address"
                            margin="normal"
                            name="email"
                            type="email"
                            variant="outlined"
                            {...formik.getFieldProps('email')}
                        />
                        <TextField
                            error={Boolean(formik.touched.password && formik.errors.password)}
                            fullWidth
                            helperText={formik.touched.password && formik.errors.password}
                            label="Password"
                            margin="normal"
                            name="password"
                            type="password"
                            variant="outlined"
                            {...formik.getFieldProps('password')}
                        />
                        <Box my={2}>
                            <Button color="primary" disabled={formik.isSubmitting} fullWidth size="large" type="submit" variant="contained">
                                Sign in
                            </Button>
                        </Box>
                        <Box my={2} display="flex" justifyContent="center">
                            <Button
                                activeClassName={classes.active}
                                className={classes.button}
                                component={RouterLink}
                                to="/auth/forgot-password"
                            >
                                <span className={classes.title}>
                                    Forgot Password?
                                </span>
                            </Button>
                        </Box>
                    </form>
                </Container>
            </Box>
        </Page>
    );
};

export default LoginView;
