import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import routes from 'src/routes';
import { SnackbarUtilsConfigurator } from './common/utils/Toast';
import { UserContextComponent } from './common/store/contexts/userContext';
import GlobalState from './common/store/contexts/GlobalContext';

const App = () => {
    const routing = useRoutes(routes);

    return (
        <GlobalState>
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={5000} disableWindowBlurListener>
                    <SnackbarUtilsConfigurator />
                    <GlobalStyles />
                    <UserContextComponent>
                        {routing}
                    </UserContextComponent>
                </SnackbarProvider>
            </ThemeProvider>
        </GlobalState>
    );
};

export default App;
