import React, { useEffect, useState } from 'react';

export const UserContext = React.createContext();

export const UserContextComponent = ({ children }) => {
    const prevAuth = localStorage.getItem('auth') || false;
    const [authenticated, setAuthenticated] = useState(prevAuth);
    useEffect(
        () => {
            localStorage.setItem('authenticated', authenticated);
        },
        [authenticated]
    );

    const defaultContext = {
        authenticated,
        setAuthenticated,
    };

    return (
        <UserContext.Provider value={defaultContext}>
            {children}
        </UserContext.Provider>
    );
};
