import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    makeStyles,
    Button,
    SvgIcon,
    NativeSelect
} from '@material-ui/core';
import { Delete, CreateTwoTone } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import usersApi from '../../../common/api/users';
import rolesApi from '../../../common/api/roles';
import UsersContext from '../../../common/store/contexts/UsersContext';
import ProjectsContext from '../../../common/store/contexts/ProjectsContext';
import UserForm from './UserForm';
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';
import UserEditForm from './UserEditForm';

const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        marginRight: theme.spacing(2)
    },
    smallColumn: {
        width: '5%'
    }
}));

const Results = ({ className, users, ...rest }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedUserIds, setselectedUserIds] = useState([]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', description: '' });
    // const [isLoading, setIsLoading] = useState(true);
    const context = useContext(UsersContext);
    const projectContext = useContext(ProjectsContext);

    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [roles, setRoles] = useState([]);
    const [userToEdit, setUserToEdit] = useState(null);

    const dialogStatHandler = (status) => {
        setOpen(status);
        setEditOpen(status);
    };

    useEffect(() => {
        setTotalItems(rest.totalitems);
        rolesApi.getRoles().then((res) => {
            setRoles(res.data);
        }).catch((error) => {
            console.log(error);
        });

        return () => {
            // cleanup here
        };
    }, []);

    const deleteUser = async (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        });
        try {
            const res = await usersApi.deleteUser(id);
            enqueueSnackbar(res && res.data && res.data.message, { variant: 'success' });
            context.removeUser(id);
        } catch (error) {
            // setSubmitting(false);
        }
    };

    const editUser = async (event, id) => {
        if (id) {
            usersApi.getUserDetail(id).then((response) => {
                setUserToEdit(response.data);
                setEditOpen(true);
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    const handleSelectAll = (event) => {
        let newSelectedUserIds;

        if (event.target.checked) {
            newSelectedUserIds = users.map((user) => user.id);
        } else {
            newSelectedUserIds = [];
        }

        setselectedUserIds(newSelectedUserIds);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedUserIds.indexOf(id);
        let newSelectedUserIds = [];

        if (selectedIndex === -1) {
            newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds, id);
        } else if (selectedIndex === 0) {
            newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds.slice(1));
        } else if (selectedIndex === selectedUserIds.length - 1) {
            newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedUserIds = newSelectedUserIds.concat(
                selectedUserIds.slice(0, selectedIndex),
                selectedUserIds.slice(selectedIndex + 1)
            );
        }

        setselectedUserIds(newSelectedUserIds);
    };

    const handleLimitChange = (event) => {
        usersApi.getUsers('', null, event.target.value).then((response) => {
            context.loadUsers(response.data.rows);
            setTotalItems(response.data.totalItems);
            setLimit(event.target.value);
            setPage(0);
        }).catch((error) => {
            console.log(error);
        });
    };

    const handlePageChange = (event, newPage) => {
        usersApi.getUsers('', newPage, limit).then((response) => {
            context.loadUsers(response.data.rows);
            setTotalItems(response.data.totalItems);
            setPage(newPage);
        }).catch((error) => {
            console.log(error);
        });
    };

    const handleRoleChange = async (event, userId) => {
        console.log('role', event.target.value);
        console.log('userId', userId);
        try {
            const response = await usersApi.updateUserRole(userId, { role_id: event.target.value });
            context.updateUser(userId, response.data.user);
            enqueueSnackbar(response && response.data && response.data.message, { variant: 'success' });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <PerfectScrollbar>
                <Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox" className={classes.smallColumn}>
                                    <Checkbox
                                        checked={users && selectedUserIds.length === users.length}
                                        color="primary"
                                        indeterminate={
                                            selectedUserIds.length > 0
                                            && selectedUserIds.length < users.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                <TableCell padding="checkbox">
                                    Name
                                </TableCell>
                                <TableCell padding="checkbox">
                                    Email
                                </TableCell>
                                <TableCell padding="checkbox">
                                    Role
                                </TableCell>
                                <TableCell className={classes.smallColumn} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users && users.slice(0, limit).map((user) => (
                                <TableRow
                                    hover
                                    key={user.id}
                                    selected={selectedUserIds.indexOf(user.id) !== -1}
                                >
                                    <TableCell padding="checkbox" className={classes.smallColumn}>
                                        <Checkbox
                                            checked={selectedUserIds.indexOf(user.id) !== -1}
                                            onChange={(event) => handleSelectOne(event, user.id)}
                                            value="true"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Box alignItems="center" display="flex">
                                            <Typography color="textPrimary" variant="body1">
                                                {user.name}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box alignItems="center" display="flex" data-cy="user-email">
                                            <Typography color="textPrimary" variant="body1">
                                                {user.email}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box alignItems="center" display="flex">
                                            {(user.role_id && roles.length)
                                                ? (
                                                    <NativeSelect
                                                        fullWidth
                                                        label="Role"
                                                        name="role_id"
                                                        value={user.role_id}
                                                        data-cy="user-role-selected"
                                                        onChange={(event) => handleRoleChange(event, user.id)}
                                                    >
                                                        {roles?.map((role) => (
                                                            <option key={role.id} value={role.id}>{role.name}</option>
                                                        ))}
                                                    </NativeSelect>
                                                ) : ''}
                                        </Box>
                                    </TableCell>
                                    <TableCell className={classes.smallColumn}>
                                        <Box alignItems="center" display="flex">
                                            <Button onClick={(event) => editUser(event, user.id)}>
                                                <SvgIcon color="inherit" className={classes.addButton}>
                                                    <CreateTwoTone />
                                                </SvgIcon>
                                            </Button>
                                            <Button onClick={() => {
                                                setConfirmDialog({
                                                    isOpen: true,
                                                    title: `Are you sure you want to delete ${user.name}`,
                                                    onConfirm: () => { deleteUser(user.id); }
                                                });
                                            }}
                                            >
                                                <SvgIcon color="inherit" className={classes.addButton}>
                                                    <Delete />
                                                </SvgIcon>
                                            </Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
            <TablePagination
                component="div"
                count={totalItems ? totalItems : rest.totalitems}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
            />
            <UserForm
                open={open}
                roles={roles}
                projects={projectContext.projects}
                onChange={(value) => { dialogStatHandler(value); }}
            />
            <UserEditForm
                open={editOpen}
                user={userToEdit}
                roles={roles}
                projects={projectContext.projects}
                onChange={(value) => { dialogStatHandler(value); }}
            />
            <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string,
    users: PropTypes.array.isRequired,
    totalItems: PropTypes.number
};

export default Results;
