import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    makeStyles,
    Button,
    SvgIcon
} from '@material-ui/core';
import { CreateTwoTone, Delete } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import projectApi from '../../../common/api/projects';
import ProjectsContext from '../../../common/store/contexts/ProjectsContext';
import ProjectForm from './ProjectForm';
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        marginRight: theme.spacing(2)
    },
    smallColumn: {
        width: '5%'
    }
}));

const Results = ({ className, projects, ...rest }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedProjectIds, setselectedProjectIds] = useState([]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', description: '' });
    // const [isLoading, setIsLoading] = useState(true);
    const context = useContext(ProjectsContext);

    const [open, setOpen] = useState(false);
    const [projectToEdit, setProjectToEdit] = useState(null);

    const dialogStatHandler = (status) => {
        setOpen(status);
    };

    useEffect(() => {
        setTotalItems(rest.totalitems);

        return () => {
            // cleanup here
        };
    }, []);

    const deleteProject = async (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        });
        try {
            const res = await projectApi.deleteProject(id);
            enqueueSnackbar(res && res.data && res.data.message, { variant: 'success' });
            context.removeProject(id);
        } catch (error) {
            // setSubmitting(false);
        }
    };

    const editProject = async (event, id) => {
        if (id) {
            projectApi.getProjectDetail(id).then((response) => {
                setProjectToEdit(response.data);
                setOpen(true);
            }).catch((error) => {
                console.log(error);
            });
        }
        setOpen(true);
    };

    const handleSelectAll = (event) => {
        let newSelectedProjectIds;

        if (event.target.checked) {
            newSelectedProjectIds = projects.map((project) => project.id);
        } else {
            newSelectedProjectIds = [];
        }

        setselectedProjectIds(newSelectedProjectIds);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedProjectIds.indexOf(id);
        let newSelectedProjectIds = [];

        if (selectedIndex === -1) {
            newSelectedProjectIds = newSelectedProjectIds.concat(selectedProjectIds, id);
        } else if (selectedIndex === 0) {
            newSelectedProjectIds = newSelectedProjectIds.concat(selectedProjectIds.slice(1));
        } else if (selectedIndex === selectedProjectIds.length - 1) {
            newSelectedProjectIds = newSelectedProjectIds.concat(selectedProjectIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedProjectIds = newSelectedProjectIds.concat(
                selectedProjectIds.slice(0, selectedIndex),
                selectedProjectIds.slice(selectedIndex + 1)
            );
        }

        setselectedProjectIds(newSelectedProjectIds);
    };

    const handleLimitChange = (event) => {
        projectApi.getProjects('', null, event.target.value).then((response) => {
            context.loadProjects(response.data.rows);
            setTotalItems(response.data.totalItems);
            setLimit(event.target.value);
            setPage(0);
        }).catch((error) => {
            console.log(error);
        });
    };

    const handlePageChange = (event, newPage) => {
        projectApi.getProjects('', newPage, limit).then((response) => {
            context.loadProjects(response.data.rows);
            setTotalItems(response.data.totalItems);
            setPage(newPage);
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <PerfectScrollbar>
                <Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox" className={classes.smallColumn}>
                                    <Checkbox
                                        checked={selectedProjectIds.length === projects.length}
                                        color="primary"
                                        indeterminate={
                                            selectedProjectIds.length > 0
                                            && selectedProjectIds.length < projects.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                <TableCell padding="checkbox">
                                    Name
                                </TableCell>
                                <TableCell className={classes.smallColumn} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projects.slice(0, limit).map((project) => (
                                <TableRow
                                    hover
                                    key={project.id}
                                    selected={selectedProjectIds.indexOf(project.id) !== -1}
                                >
                                    <TableCell padding="checkbox" className={classes.smallColumn}>
                                        <Checkbox
                                            checked={selectedProjectIds.indexOf(project.id) !== -1}
                                            onChange={(event) => handleSelectOne(event, project.id)}
                                            value="true"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Box alignItems="center" display="flex" data-cy="project-name">
                                            <Typography color="textPrimary" variant="body1">
                                                {project.name}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell className={classes.smallColumn}>
                                        <Box alignItems="center" display="flex">
                                            <Button onClick={(event) => editProject(event, project.id)}>
                                                <SvgIcon color="inherit" className={classes.addButton}>
                                                    <CreateTwoTone />
                                                </SvgIcon>
                                            </Button>
                                            <Button onClick={() => {
                                                setConfirmDialog({
                                                    isOpen: true,
                                                    title: `Are you sure you want to delete project ${project.name}`,
                                                    onConfirm: () => { deleteProject(project.id); }
                                                });
                                            }}
                                            >
                                                <SvgIcon color="inherit" className={classes.addButton}>
                                                    <Delete />
                                                </SvgIcon>
                                            </Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
            <TablePagination
                component="div"
                count={totalItems ? totalItems : rest.totalitems}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
            />
            <ProjectForm open={open} project={projectToEdit} onChange={(value) => { dialogStatHandler(value); }} />
            <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </Card>
    );
};

Results.propTypes = {
    className: PropTypes.string
};

export default Results;
