import API from '../utils/API';

const baseUrl = '/projects';

const projectApi = {
    getProjects: async (keyword, page, size) => {
        const params = {
            keyword,
            page,
            size
        };
        const query = Object.keys(params)
            .map((k) => ((params[k]) ? `${k}=${params[k]}` : ''))
            .join('&');
        return API.get(`${baseUrl}?${query}`);
    },

    saveProject: async (model) => {
        return API.post(`${baseUrl}`, model);
    },

    getProjectDetail: (id) => {
        return API.get(`${baseUrl}/${id}`);
    },

    updateProjectDetail: (id, model) => {
        return API.put(`${baseUrl}/${id}`, model);
    },

    deleteProject: (id) => {
        return API.delete(`${baseUrl}/${id}`);
    }
};

export default projectApi;
