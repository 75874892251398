import API from '../utils/API';

const baseUrl = '/roles';

const roleApi = {
    getRoles: async () => {
        return API.get(`${baseUrl}`);
    },
};

export default roleApi;
