import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    IconButton,
    DialogTitle,
    makeStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import projectApi from '../../../common/api/projects';
import ProjectsContext from '../../../common/store/contexts/ProjectsContext';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    addButton: {
        fontSize: 25
    },
    homeButton: {
        fontSize: 25
    },
    usersButton: {
        fontSize: 25
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 400,
        minHeight: 200,
        padding: 20
    }
}));

function ProjectForm(props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const context = useContext(ProjectsContext);

    const handleClose = () => {
        props.onChange(false);
    };

    const formik = useFormik({
        initialValues: {
            name: (props.project)? props.project.name : '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required('Project Name is required'),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            let res = null;
            try {
                if (props.project) {
                    res = await projectApi.updateProjectDetail(props.project.id, values);
                    console.log(res.data.project);
                    context.updateProject(props.project.id, res.data.project);
                } else {
                    res = await projectApi.saveProject(values);
                    context.addProject(res.data.project);
                }
                enqueueSnackbar(res && res.data && res.data.message, { variant: 'success' });
                handleClose(false);
                resetForm({});
            } catch (error) {
                setSubmitting(false);
            }
        },
        enableReinitialize: true
    });

    return (
        <div>
            <Dialog maxWidth="lg" open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6">{ (props.project)? 'Edit Project': 'Add Project'}</Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form noValidate onSubmit={ formik.handleSubmit} className={classes.formControl}>
                        <TextField
                            error={Boolean(formik.touched.name && formik.errors.name)}
                            fullWidth
                            helperText={formik.touched.name && formik.errors.name}
                            label="Project Name"
                            margin="normal"
                            name="name"
                            type="text"
                            variant="outlined"
                            className={classes.textField}
                            {...formik.getFieldProps('name')}
                        />
                        <Box my={2} display="flex" justifyContent="flex-end">
                            <Button
                                color="primary"
                                disabled={formik.isSubmitting}
                                size="large"
                                data-cy="submit-button"
                                type="submit"
                                variant="contained"
                            >
                                { (props.project) ? 'Update Project' : 'Add Project' }
                            </Button>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}

ProjectForm.propTypes = {
    open: PropTypes.bool.isRequired,
    onChange: PropTypes.func
};

export default ProjectForm;
