import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    TextField,
    Dialog,
    DialogContent,
    Typography,
    IconButton,
    DialogTitle,
    NativeSelect,
    Select,
    FormControl,
    InputLabel,
    makeStyles,
    Input,
    MenuItem,
    Checkbox,
    ListItemText,
    Chip
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import usersApi from '../../../common/api/users';
import UsersContext from '../../../common/store/contexts/UsersContext';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    addButton: {
        fontSize: 25
    },
    homeButton: {
        fontSize: 25
    },
    usersButton: {
        fontSize: 25
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        marginTop: theme.spacing(2),
        padding: 10,
        minWidth: 350,
        display: 'block'
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function UserEditForm({
    user,
    roles,
    projects,
    ...rest
}) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const context = useContext(UsersContext);
    const [selectedProjects, setSelectedProjects] = React.useState(user && user.projects);

    const handleChange = (event) => {
        console.log('handleChange', event);
        setSelectedProjects(event.target.value);
        console.log('projects', selectedProjects);
    };

    const handleClose = () => {
        rest.onChange(false);
    };

    const formik = useFormik({
        initialValues: {
            first_name: user && user.first_name,
            last_name: user && user.last_name,
            email: user && user.email,
            password: '',
            confirm_password: '',
            role_id: user && user.role_id,
            project_id: user && user.projects,
        },
        validationSchema: Yup.object().shape({
            first_name: Yup.string().required('First name is required'),
            last_name: Yup.string().required('Last name is required'),
            email: Yup.string().required('Email is required').email('Enter a valid email'),
            password: Yup.string().min(8),
            confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
            role_id: Yup.string().required('Role is required'),
            project_id: Yup
                .array()
                .when('role_id', {
                    is: (roleId) => {
                        return roleId === '2';
                    },
                    then: Yup
                        .array()
                        .min(1, 'At least one project is required for Project Builder')
                })
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            let res = null;
            try {
                if (user) {
                    res = await usersApi.updateUserDetail(user.id, values);
                    console.log(res.data.user);
                    context.updateUser(user.id, res.data.user);
                }
                enqueueSnackbar(res && res.data && res.data.message, { variant: 'success' });
                handleClose(false);
                resetForm({});
            } catch (error) {
                setSubmitting(false);
            }
        },
        enableReinitialize: true
    });

    return (
        <div>
            <Dialog maxWidth="lg" open={rest.open} onClose={handleClose} aria-labelledby="form-dialog-title" data-cy="user-form">
                <DialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6">{(rest.user) ? 'Edit User' : 'Add User'}</Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form noValidate onSubmit={formik.handleSubmit} className={classes.formControl} autoComplete="new-password">
                        <FormControl className={classes.formControl}>
                            <TextField
                                error={Boolean(formik.touched.first_name && formik.errors.first_name)}
                                fullWidth
                                helperText={formik.touched.first_name && formik.errors.first_name}
                                label="First Name"
                                name="first_name"
                                type="text"
                                variant="outlined"
                                className={classes.textField}
                                {...formik.getFieldProps('first_name')}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                error={Boolean(formik.touched.last_name && formik.errors.last_name)}
                                fullWidth
                                helperText={formik.touched.last_name && formik.errors.last_name}
                                label="Last Name"
                                name="last_name"
                                type="text"
                                variant="outlined"
                                className={classes.textField}
                                {...formik.getFieldProps('last_name')}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                error={Boolean(formik.touched.email && formik.errors.email)}
                                fullWidth
                                helperText={formik.touched.email && formik.errors.email}
                                label="User Email"
                                name="email"
                                type="email"
                                variant="outlined"
                                className={classes.textField}
                                {...formik.getFieldProps('email')}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                error={Boolean(formik.touched.password && formik.errors.password)}
                                fullWidth
                                helperText={formik.touched.password && formik.errors.password}
                                label="Password"
                                margin="normal"
                                name="password"
                                type="password"
                                variant="outlined"
                                autoComplete="new-password"
                                {...formik.getFieldProps('password')}
                            />
                            <TextField
                                error={Boolean(formik.touched.confirm_password && formik.errors.confirm_password)}
                                fullWidth
                                helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                                label="Confirm Password"
                                margin="normal"
                                name="confirm_password"
                                type="password"
                                variant="outlined"
                                {...formik.getFieldProps('confirm_password')}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel color="secondary">Role</InputLabel>
                            <NativeSelect
                                error={Boolean(formik.touched.role_id && formik.errors.role_id)}
                                fullWidth
                                label="Role"
                                name="role_id"
                                data-cy="role-select"
                                {...formik.getFieldProps('role_id')}
                            >
                                <option aria-label="None" value="" />
                                {roles?.map((role) => (
                                    <option key={role.id} value={role.id}>{role.name}</option>
                                ))}
                            </NativeSelect>
                        </FormControl>
                        {formik.values.role_id == 2 && (
                            <FormControl className={classes.formControl}>
                                <InputLabel>Projects</InputLabel>
                                <Select
                                    error={Boolean(formik.touched.project_id && formik.errors.project_id)}
                                    labelId="demo-mutiple-checkbox-label"
                                    id="demo-mutiple-checkbox"
                                    fullWidth
                                    multiple
                                    data-cy="project-select"
                                    value={selectedProjects}
                                    label="Project"
                                    input={<Input />}
                                    onClick={handleChange}
                                    renderValue={selected => (
                                        <div>
                                            {selected.map(value => (
                                                <Chip
                                                    className={classes.chip}
                                                    key={value}
                                                    label={projects.map(project => (
                                                        (project.id === value) ? project.name : ''
                                                    ))}
                                                />
                                            ))}
                                        </div>
                                    )}
                                    {...formik.getFieldProps('project_id')}
                                    MenuProps={MenuProps}
                                >
                                    {projects?.map((project) => (
                                        <MenuItem key={project.id} value={project.id}>
                                            <Checkbox checked={formik.values.project_id?.indexOf(project.id) > -1} />
                                            <ListItemText primary={project.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        <Box my={2} display="flex" justifyContent="flex-end">
                            <Button
                                color="primary"
                                disabled={formik.isSubmitting}
                                size="large"
                                type="submit"
                                data-cy="submit-button"
                                variant="contained"
                            >
                                {(user) ? 'Update User' : 'Add User'}
                            </Button>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}

UserEditForm.propTypes = {
    open: PropTypes.bool.isRequired,
    onChange: PropTypes.func
};

export default UserEditForm;
