import React, { useState, useContext } from 'react';
import {
    Outlet,
    Navigate
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { UserContext } from '../../common/store/contexts/userContext';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        }
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto'
    }
}));

const DashboardLayout = () => {
    const classes = useStyles();
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const { authenticated } = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();

    if (authenticated) {
        return (
            <div className={classes.root}>
                <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
                <NavBar
                    onMobileClose={() => setMobileNavOpen(false)}
                    openMobile={isMobileNavOpen}
                />
                <div className={classes.wrapper}>
                    <div className={classes.contentContainer}>
                        <div className={classes.content}>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    enqueueSnackbar('Your either don\'t have a session or it\'s expred', { variant: 'error' });
    return (
        <Navigate to="/auth/login" />
    );
};

export default DashboardLayout;
