import axios from 'axios';
import SnackbarUtils from './Toast';

const baseInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});
baseInstance.defaults.headers.common.Accept = 'application/json';
baseInstance.defaults.headers.common['Content-Type'] = 'application/json';

// Request Interceptor
baseInstance.interceptors.request.use((config) => {
    let auth = localStorage.getItem('auth');
    if (auth) {
        auth = JSON.parse(auth);
        if (auth && auth.token) {
            config.headers.Authorization = `Bearer ${auth.token}`;
        }
    }
    return config;
}, (error) => {
    console.log('axios request Inteceptor', error);
    return Promise.reject(error);
});

// Response Interceptor
baseInstance.interceptors.response.use((response) => {
    return response;
}, (error) => {
    console.log('axios response Inteceptor', error);
    if (error && error.response) {
        let errorMessage = error.response && error.response.data && error.response.data.message;
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('auth');
            localStorage.removeItem('authenticated');
        } else if (error.response && error.response.status === 403 && !errorMessage) {
            errorMessage = 'Action not authorized';
        }

        SnackbarUtils.error(errorMessage);
    } else {
        const errorMessage = (error.message) === 'Network Error' ? 'Network Error! Unknown error while connecting to server' : error.message;
        SnackbarUtils.error(errorMessage);
    }
    return Promise.reject(error);
});
export default baseInstance;
