import React from 'react';

const Logo = (props) => {
    return (
        <img
            alt="Logo"
            className="header-logo"
            src="/static/images/logo-header.png"
            {...props}
        />
    );
};

export default Logo;
