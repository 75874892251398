import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: theme.spacing(2),
        postion: 'absolute',
        top: theme.spacing(5)
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogActions: {
        alignContent: 'center'
    }
}));

const ConfirmDialog = ({
    confirmDialog,
    setConfirmDialog,
    onSubmit,
    onClose
}) => {
    const classes = useStyles();
    return (
        <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
            <DialogTitle>{confirmDialog.title}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <DialogContentText>{confirmDialog.description}</DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button variant="contained" color="secondary" onClick={confirmDialog.onConfirm}>
                    YES
                </Button>
                <Button variant="contained" color="primary" autoFocus onClick={() => { setConfirmDialog({ ...confirmDialog, isOpen: false }); }}>
                    CANCEL
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
